import React, { useState } from "react";
import "./style.contactus.css";  // Renamed to avoid dots
import { FooterInner } from '../../components/Homescreen/FooterInner'
import { FAQS } from "../../utils/utils";
import {ContactUsPopup} from "../../components/ContactUsPopup";

export const ContactUs = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => setOpenIndex((prevIndex) => (prevIndex === index ? null : index))

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);


const changeActive = (tab) => setActiveTab(tab)

  return (
    <div class="container-fluid contact-us">
      <div className="main-wrapper">
        {isOpen &&
          <ContactUsPopup
            closeModal={closeModal}
        />}
        <p className="how-it-works-text">Need Help? Start here</p>
        <h2 className="main-heading">Frequently asked question</h2>

        <div className="faq-tabs">
          <ul>
            <li
              onClick={() => changeActive(0)}
              className={activeTab === 0 && "active"}
            >
              About Samvaad
            </li>
            <li
              onClick={() => changeActive(1)}
              className={activeTab === 1 && "active"}
            >
              Privacy and Security
            </li>
            <li
              onClick={() => changeActive(2)}
              className={activeTab === 2 && "active"}
            >
              Assistant Setup
            </li>
          </ul>

          <div className="Tab-one">
          {FAQS.map((faq, index) => (
            <div key={index} className="tab-content">
              <div
                className="faq-question"
                onClick={() => toggleFAQ(index)}
                role="button"
                tabIndex="0"
              >
                {faq.question}
                <img
                  className={`icon-down ${openIndex === index ? "open" : ""}`}
                  alt="Down arrow"
                  src={openIndex === index ? "/img/icon-up.svg" : "/img/down-arrow.svg"}
                />
              </div>
              {openIndex === index && (
                <div className="faq-answer">{faq.answer}</div>
              )}
            </div>
          ))}
        </div>
        </div>
      </div>

      <div className="data-widget">
        <h3>
          <caption>Our ninjas are ready and here to help you</caption>
        </h3>
        <p>
          Whether you’re curious, skeptical or just want to know how it would
          work for you, we’re hear to help{" "}
        </p>
        <span onClick={openModal} className="contact-cta">
          <a class="white-btn">Contact Us</a>{" "}
        </span>
      </div>

      <FooterInner
        openContactUsModal={openModal}
      />
    </div>
  );
};
