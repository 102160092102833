import React, { useEffect, useRef, useState } from "react";
import "../screens/ContactUs/style.contactus.css";  // Renamed to avoid dots

export const ContactUsPopup = (props) => {
  const [name, setName] = useState('')
  const [number, setNumber] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  
  const handleOverlayClick = (event) => {
    if (event.target.className === "modal-overlay contact-modal") {
      props.closeModal();
    }
  };

  const submitForm = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      name: name,
      number: number,
      email: email,
      message: message,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://dev.samvaad.ai/utils/contact", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  }

  return (
    <div className="modal-overlay contact-modal" onClick={handleOverlayClick}>
      <div className="main-popup">
        <button onClick={props.closeModal} className="modal-close">
          <img alt="close Modal" src="/img/modal-close.svg" />
        </button>

        <div className="modal-content">
          <h3>Tell us how we can help you</h3>

          <label for="fname">Name</label>
          <input type="text" id="fname" name="fname" placeholder="Full Name" onChange={e => setName(e.target.value)}/>

          <label for="mobile">Phone Number</label>
          <input
            type="text"
            id="mobile"
            name="mobile"
            placeholder="10 Digit Mobile No."
            onChange={e => setNumber(e.target.value)}
          />

          <label for="email">Email Id</label>
          <input
            type="text"
            id="email"
            name="email"
            placeholder="Registered email"
            onChange={e => setEmail(e.target.value)}
          />

          <label for="message">Message</label>
          <textarea
            id="message"
            name="message"
            rows="4"
            cols="50"
            placeholder="Need help in..."
            onChange={e => setMessage(e.target.value)}
          ></textarea>

          <button
            type="submit"
            form="form1"
            value="Submit"
            className="submit-btn"
            onClick={() => {
              console.log(name)
              console.log(number)
              console.log(email)
              console.log(message)
              submitForm()
            }}
          >
            Submit
          </button>
          <span className="helper-text">
            You can also email us at{" "}
            <a href="mailto:contactus@samvaad.ai">contactus@samvaad.ai</a>
          </span>
        </div>
      </div>
    </div>
  );
};
