import React, { useEffect, useRef, useState } from "react";
// import { NumberInput } from "../../components/NumberInput";
// import { UserCard } from "../../components/UserCard";
// import { IcAlert1 } from "../../icons/IcAlert1";

// import { IcTag1 } from "../../icons/IcTag1";
// import { MdiPhone1 } from "../../icons/MdiPhone1";
// import { MdiVideo1 } from "../../icons/MdiVideo1";
// import { SwapMe } from "../../icons/SwapMe";
import "./style.home.css";
import { Quicklinks } from '../../components/Homescreen/Quicklinks'
import Slider from "../../components/Homescreen/Slider";
import CardRightOne from "../../components/Homescreen/Cards/CardRightOne";
import CardRightTwo from "../../components/Homescreen/Cards/CardRightTwo";
// import CardRightThree from "../../components/Homescreen/Cards/CardRightThree";
import CardLeftFour from "../../components/Homescreen/Cards/CardLeftFour";
import CardLeftThree from "../../components/Homescreen/Cards/CardLeftThree";
import CardLeftTwo from "../../components/Homescreen/Cards/CardLeftTwo";
import CardLeftOne from "../../components/Homescreen/Cards/CardLeftOne";
import CardRightFour from "../../components/Homescreen/Cards/CardRightFour";
import CardLeftFive from "../../components/Homescreen/Cards/CardLeftFive";
import CardRightFive from "../../components/Homescreen/Cards/CardRightFive";
import Header from "../../components/Header";
// import CustomAudioPlayer from "../../components/Homescreen/CustomAudio/CustomAudioPlayer";
import VideoComponent from '../../components/Homescreen/Video'
import { Benefits } from "../../components/Homescreen/Benefits";
import { AudioExperience } from "../../components/Homescreen/AudioExperience";
import { SafeAndSecure } from "../../components/Homescreen/SafeAndSecure";
import StackedCards from "../../components/Homescreen/StackedCards";
import Feature from "../../components/Homescreen/Features";
import Flow from "../../components/Homescreen/Flow";
import NoiseSection from "../../components/Homescreen/NoiseSection";
import { CARD_SPEED } from "../../utils/utils";
import { ContactUsPopup } from "../../components/ContactUsPopup";

export const Home = () => {
  const [scrollY, setScrollY] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const globalAudioRef = useRef(null);
  const [playingAudio, setPlayingAudio] = useState(null);
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  const openModal = () => setIsOpen(true);

  const closeModal = () => setIsOpen(false)

  return (
    <div className="frame">

      {/* first view */}
      <div className="container-fluid">

        <div className="main-wrapper">
        {isOpen && <ContactUsPopup closeModal={closeModal} />}
        <div className="main-header-wrapper-inside">

          <h2 className="main-heading">AI-Driven Personal Assistant</h2>
          <p className="paragraph-text">Empowering seamless communication. Samvaad's AI assistant empowers you to stay productive, safe and highly efficient, effortlessly.</p>

          

           <div className="app-download-widget">
                <img alt="gpay" src="/img/googlePlay.svg" />
                <img alt="applepay" src="/img/applePay.svg" />
                <span className="barcode-img"> <img alt="Image" src="/img/barcode.png" />  </span>
                <span onClick={setIsOpen} className="default-link">Try It Free</span>
            </div> 
          </div>

            <div className="splash-banner">

            <img alt="Splash Screen" src="/img/sprite/png/splash-screen.png" className="splash-main-img"/>
            <img style={{transform: `translateY(${-scrollY * CARD_SPEED.LEFT_FOUR}px)`}} alt="applepay" src="/img/sprite/png/splash-img-01.png" className="splash-img-01"/>
            <img style={{transform: `translateY(${-scrollY * CARD_SPEED.LEFT_FIVE}px)`}} alt="applepay" src="/img/sprite/png/splash-img-02.png" className="splash-img-02"/>
            <img style={{transform: `translateY(${-scrollY * CARD_SPEED.LEFT_ONE}px)`}} alt="applepay" src="/img/sprite/png/splash-img-03.png" className="splash-img-03"/>
            <img style={{transform: `translateY(${-scrollY * CARD_SPEED.LEFT_TWO}px)`}} alt="applepay" src="/img/sprite/png/splash-img-04.png" className="splash-img-04"/>
            <img style={{transform: `translateY(${-scrollY * CARD_SPEED.LEFT_THREE}px)`}} alt="applepay" src="/img/sprite/png/splash-img-05.png" className="splash-img-05"/>
            <img style={{transform: `translateY(${-scrollY * CARD_SPEED.RIGHT_ONE}px)`}} alt="applepay" src="/img/sprite/png/splash-img-06.png" className="splash-img-06"/>
            <img style={{transform: `translateY(${-scrollY * CARD_SPEED.RIGHT_TWO}px)`}} alt="applepay" src="/img/sprite/png/splash-img-07.png" className="splash-img-07"/>
            <img style={{transform: `translateY(${-scrollY * CARD_SPEED.RIGHT_THREE}px)`}} alt="applepay" src="/img/sprite/png/splash-img-08.png" className="splash-img-08"/>
            <img style={{transform: `translateY(${-scrollY * CARD_SPEED.RIGHT_FOUR}px)`}} alt="applepay" src="/img/sprite/png/splash-img-09.png" className="splash-img-09"/>
            <img style={{transform: `translateY(${-scrollY * CARD_SPEED.RIGHT_FIVE}px)`}} alt="applepay" src="/img/sprite/png/splash-img-10.png" className="splash-img-10"/>

          </div>
          </div>
      </div>







      <StackedCards />
      <Feature />

      <Flow />

      <NoiseSection />

      <div className="privacy-widget">
        <div className="privacy-inner-widget">

              <SafeAndSecure />

              <AudioExperience />         
     
        </div>
      </div>

      <Benefits />

    {/*  <VideoComponent
        videoSrc={'https://www.w3schools.com/html/mov_bbb.mp4'}
        thumbnailSrc={'/img/black-bg.jpg'}
      />*/}

      <Slider />
      <Quicklinks openContactModal={openModal} />
    </div>
  );
};

