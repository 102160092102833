export const PrivacyPopupData = [
  {
    title: "Data We Collect:",
    desc: [
      "Call Details: Date, time, duration, and call type.",
      "Contacts Metadata: Caller name and phone number for better organization.",
      "App Usage: Patterns to improve user experience.",
      "Preferences: Settings for call recording and filtering.",
      "Device Information: Ensures compatibility and functionality.",
    ],
    subTitle:
      "We only collect what’s essential to provide and enhance our services.",
  },
  {
    title: "How Your Data is Used:",
    desc: [
      "Call Management: Block spam and highlight important calls.",
      "Personalized Insights: Summaries tailored to your activity.",
      "AI Improvements: Enhance spam detection and call categorization.",
      "Notifications: Keep you updated about relevant features.",
    ],
    subTitle:
      "Your data is solely used to improve your experience with Samvaad.",
  },
  {
    title: "Your Data Security:",
    desc: [
      "End-to-End Encryption: Protects your conversations from start to finish.",
      "Access Control: Only you can view or manage your data.",
      "No Sharing Without Consent: We never sell or share your data.",
      "Global Compliance: Adherence to strict international privacy standards.",
    ],
    subTitle:
      "Your trust drives our promise to keep your data secure.",
  },
];